import { render, staticRenderFns } from "./ClientFooter.vue?vue&type=template&id=0c5f5b52&scoped=true"
import script from "./ClientFooter.vue?vue&type=script&lang=js"
export * from "./ClientFooter.vue?vue&type=script&lang=js"
import style0 from "./ClientFooter.vue?vue&type=style&index=0&id=0c5f5b52&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c5f5b52",
  null
  
)

export default component.exports